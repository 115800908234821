.hall-review-form {
  margin: 1rem;
}

.hall-review-form input,
.hall-review-form button,
.hall-review-form textarea,
.hall-review-form select {
  border-radius: 0 !important;
}

.hall-review-edit-form input,
.hall-review-edit-form button,
.hall-review-edit-form textarea,
.hall-review-edit-form select {
  border-radius: 0 !important;
}

.form-container button {
  background-color: #27374d;
}

.sort-reviews-dropdown button,
.sort-reviews-dropdown button:active,
.sort-reviews-dropdown button:focus,
.sort-reviews-dropdown button:hover {
  background-color: #27374d !important;
}

.sort-reviews-dropdown button,
ul {
  border-radius: 0 !important;
}

.form-container {
  background-color: white;
  height: 100%;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
}
