@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dde6ed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-navigation {
  background-color: #27374d !important;
  color: white !important;
}

.hover-cursor:hover {
  cursor: pointer;
}

.hall-img {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.container {
  width: 70%;
}

.hall-img-section {
  padding: 0;
  padding-bottom: 1rem;
  width: 100%;
  /* height: 100%; */
  margin: 0;
  background-color: #dde6ed;
}

.hall-fact-list {
  margin-top: 1rem;
  padding-left: 1rem !important;
}

.hall-fact-list li {
  list-style-type: none;
  padding-left: 0 !important;
}

.hall-reviews-container,
.hall-info-container {
  padding: 30px 13% 0 !important;
  margin-bottom: none !important;
  min-height: 60vh;
}

.hall-reviews-container .card {
  border-radius: 0 !important;
  border: none;
  color: black;
  background-color: #ededed;
  font-weight: 200;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.hall-reviews-container .card:hover {
  cursor: pointer;
}

.hall-reviews-container .card .review-header {
  background-color: inherit !important;
  border: none !important;
  margin-bottom: 0 !important;
}

.hall-reviews-container .card .review-header .review-title {
  display: inline-block;
  width: 85%;
}

.image-banner {
  margin-top: 0;
  height: 400px;
  width: 100%;
  padding: 0;
  position: relative;
}

.image-banner .image-overlay {
  position: absolute;
  top: 0;
  background: linear-gradient(transparent, black);
  width: 100%;
  height: 87%;
}

.image-banner .inner-banner {
  position: absolute;
  bottom: 75px;
  left: 13%;
  color: white;
  font-size: 200;
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.image-banner .inner-banner h2,
.image-banner .inner-banner button {
  display: inline-block;
}

.image-banner .inner-banner button {
  margin-left: 30px;
  font-size: 0.7rem;
  padding: 0.3rem 0.7rem;
  background-color: orange;
  color: white;
  border-radius: none !important;
}

.hall-img {
  width: 100%;
  height: 87%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.ribbon {
  position: absolute;
  bottom: 0;
  background-color: #27374d;
  height: 13%;
  padding: 0 !important;
  width: 100%;
}

.facts-list {
  padding-left: 13% !important;
  height: 100%;
  line-height: 50px;
  color: white;
}

.facts-list .text-muted {
  color: rgb(186, 186, 186) !important;
}

.facts-list li {
  list-style-type: none;
  float: left;
  margin-right: 2rem;
  padding-left: 0 !important;
}

.review-pagination {
  display: flex;
  justify-content: center;
}

.selected-review {
  /* box-shadow: 0 0 0 2px rgba(218,102,123,1), 8px 8px 0 0 rgba(218,102,123,1) !important; */
  border: 2px solid rgba(218, 102, 123, 1) !important;
}

/* Styles for the review card modal */
.review-card-modal .modal-content,
.profile-page-modal .modal-content {
  border-radius: 0;
}

.review-card-modal .close-modal-btn,
.profile-page-modal .close-modal-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.settings-actions button {
  width: 100%;
}

.settings-actions p {
  font-size: 0.8rem;
  margin-bottom: 0 !important;
  display: inline-block;
  height: 100%;
  line-height: 100%;
}

.settings-actions .btn {
  border-radius: 0;
  font-size: 0.8rem;
  float: right;
  max-width: 250px;
}

.profile-page-modal .btn {
  border-radius: 0;
}

.account-toast {
  position: fixed;
  z-index: 10000000000000;
  left: 30px;
  bottom: 30px;
  padding: 0.3rem;
  color: white;
}

/* Home Page */
.homepage-grid .card {
  box-shadow: 0 0 7px #27374d;
}

.card-img-container {
  height: 200px;
  overflow: hidden;
  width: 100%;
}

.homepage-grid .card-img {
  transition: transform 0.2s;
}

.homepage-grid .card-img:hover {
  transform: scale(1.05);
}

.ribbon-below-map {
  width: 100%;
  margin: 0;
  cursor: pointer;
  height: 0px;
  background-color: rgb(39, 55, 77);
  box-shadow: rgba(39, 55, 77, 0.3) 0px 5px, rgba(39, 55, 77, 0.2) 0px 10px,
    rgba(39, 55, 77, 0.1) 0px 15px, rgba(39, 55, 77, 0.05) 0px 20px;
}

.homepage-map {
  box-shadow: rgba(39, 55, 77, 0.8) 0px 5px, rgba(39, 55, 77, 0.6) 0px 10px,
    rgba(39, 55, 77, 0.4) 0px 15px, rgba(39, 55, 77, 0.2) 0px 20px,
    rgba(39, 55, 77, 0.1) 0px 25px, rgba(39, 55, 77, 0.05) 0px 30px;
  width: 100%;
  height: 500px;
  transition: height 0.5s;
}

.fa-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}

.fa-icon-container svg {
  cursor: pointer;
}

.close-map-icon {
  position: absolute;
  top: 480px;
  left: 20px;
  font-size: 1.5rem;
  z-index: 10000000;
  background-color: rgb(39, 55, 77);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.close-map-icon:hover {
  background-color: rgb(24, 37, 54);
}

.close-map-icon svg {
  margin: 0 auto;
}

.hall-card-body {
  font-size: 0.85rem;
}

.nav-map-icon {
  position: relative;
  right: 12px;
  top: 7px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s;
}

.nav-map-icon:hover {
  color: rgb(228, 228, 228);
}
